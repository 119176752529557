

































































































































































































@import '~@/assets/styles/helpers/_variables.scss';
.select-card {
  flex-wrap: wrap;
  min-width: 60% !important;
  max-width: 60% !important;
  @media(max-width: 992px ) {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  &-content{
    width: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    border-bottom: 1px solid grey;
  }
}
.select-date {
  width: 100%;
  display: flex;
  flex-flow: center;
  flex-wrap: nowrap;
  justify-content: center;
  font-size:18px;
  &-title {
    color: #1cb4ff;
    font-size: 18px;
  }
  &-back,
  &-next {
    padding: 0 10px;
    cursor: pointer;
  }
}

.tabs-content {
  border-bottom: 1px solid grey;
  display: block;
  width: 100%;
}

